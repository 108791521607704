import { Route, TypeOfScreen } from 'types';
import { COUNTABLE_SCREENS, TYPE_OF_SCREEN_ENUM } from '@sharedConstants';
import fisherYatesShuffle from '@utils/fisherYatesShuffle';
import fixedShuffle from '@utils/fixedShuffle';

/** a very game specific route shuffling function
 * It returns a given list of routes, and then shuffles them according to passes variables
 * It also appends an end route once shuffled
 */
interface routeShufflerProps {
  /** an unmolested list of routes supplied (typically) directly by api */
  allRoutes: Route[];
  /** optional seperate list of designated pooled routes */
  pooledRoutes?: Route[];
}
const routeShuffler = ({
  allRoutes,
  pooledRoutes = [],
}: routeShufflerProps): Route[] => {
  let tmpAllRoutes = [...allRoutes];
  // if this is the case, we also have to see what routes are marked as "sticky" and if NOT randomize them
  // if we have a route with a typeOfScreen of "ClaimPoolStub", we need to swap it with items from routemaps pooled array
  const shuffledPooledRoutes = fisherYatesShuffle<Route>([...pooledRoutes]);

  if (
    shuffledPooledRoutes.length > 0 &&
    tmpAllRoutes.some(r => r.typeOfScreen === TYPE_OF_SCREEN_ENUM.ClaimPoolStub)
  ) {
    // swap out our ClaimPoolStub with the pooled items
    // do this until we have exhausted pooled routes
    tmpAllRoutes = tmpAllRoutes.map(route => {
      if (route.typeOfScreen === TYPE_OF_SCREEN_ENUM.ClaimPoolStub) {
        const pooledRoute = shuffledPooledRoutes.shift() as Route;
        return {
          ...route,
          name: pooledRoute.name,
          url: pooledRoute.url,
          typeOfScreen: pooledRoute.typeOfScreen,
          isFromPool: pooledRoute.isFromPool,
          hasFollowup: route?.hasFollowup,
        };
      }
      return route;
    });
  }

  tmpAllRoutes = fixedShuffle({
    array: tmpAllRoutes,
    peg: { key: 'isSticky', value: true },
    softPeg: {
      key: 'typeOfScreen',
      value: TYPE_OF_SCREEN_ENUM.Interrupt,
    },
    dynamicPeg: {
      key: 'isFromPool',
      value: true,
    },
    // TODO: if we turn off randomization or maybe have controls for this in future, then the shuffling need not be done here
    shouldShuffleSoftPegs: true,
    shouldShuffleDynamicPegs: true,
    shouldShuffleRemaining: true,
  }) as Route[];

  // return these shuffled routes, but tack on an end game screen too
  return [
    ...tmpAllRoutes,
    {
      name: 'end',
      url: '/end',
      index: (tmpAllRoutes?.[tmpAllRoutes.length - 1]?.index ?? 0) + 1,
      typeOfScreen: TYPE_OF_SCREEN_ENUM.End,
      isSticky: true,
    },
  ];
};

export default routeShuffler;
